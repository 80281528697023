import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//img
import athlete from "../img/athlete-small.png";
import theracer from "../img/theracer-small.png";
import goodtimes from "../img/goodtimes-small.png";
//animation
import { motion } from "framer-motion";
import {
  pageAnimation,
  fade,
  photoAnim,
  lineAnim,
  slider,
  sliderContainer,
} from "../animation";
import ScrollTop from "../components/ScrollTop";

const OurWork = () => {
  //const [element, controls] = useScroll();
  //const [element2, controls2] = useScroll();

  return (
    <Work
      exit='exit'
      variants={pageAnimation}
      initial='hidden'
      animate='show'
      style={{ background: "#fff" }}
    >
      <motion.div variants={sliderContainer}>
        <Frame1 variants={slider}></Frame1>
        <Frame2 variants={slider}></Frame2>
        <Frame3 variants={slider}></Frame3>
        <Frame4 variants={slider}></Frame4>
      </motion.div>
      <Movie>
        <motion.h2 variants={fade}>the athlete</motion.h2>
        <motion.div variants={lineAnim} className='line'></motion.div>
        <Link to='/work/the-athlete'>
          <motion.img variants={photoAnim} src={athlete} alt='athlete' />
        </Link>
      </Movie>
      <Movie
      // ref={element}
      // variants={fadeWithoutDelay}
      // initial='hidden'
      // animate={controls}
      >
        <motion.h2 variants={fade}>the racer</motion.h2>
        <motion.div className='line'></motion.div>
        <Link to='/work/the-racer'>
          <motion.img variants={photoAnim} src={theracer} alt='a racer' />
        </Link>
      </Movie>
      <Movie
      // ref={element2}
      // variants={fadeWithoutDelay}
      // initial='hidden'
      // animate={controls2}
      >
        <motion.h2 variants={fade}>good times</motion.h2>
        <motion.div className='line'></motion.div>
        <Link to='/work/good-times'>
          <motion.img variants={photoAnim} src={goodtimes} alt='goodtimes' />
        </Link>
      </Movie>
      <ScrollTop />
    </Work>
  );
};
const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 10rem;
  h2 {
    padding: 1rem 0;
  }
  @media (max-width: 1300px) {
    padding: 1rem;
  }
`;
const Movie = styled(motion.div)`
  padding-bottom: 10rem;
  .line {
    background: #23d997;
    height: 0.5rem;
    margin-bottom: 3rem;
  }
  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }
`;

//frame animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  height: 100vh;
  background: #fffebf;
  z-index: 2;
`;
const Frame2 = styled(Frame1)`
  background: #ff8efb;
`;
const Frame3 = styled(Frame1)`
  background: #8ed2ff;
`;
const Frame4 = styled(Frame1)`
  background: #8effa0;
`;

export default OurWork;
