import React from "react";
import { Link } from "react-router-dom";
import home1img from "../img/home1.png";
import { About, Description, Image, Hide } from "../styles";
import Wave from "./Wave";
//framer motion
import { motion } from "framer-motion";
import { titelAnimation, fade, photoAnim } from "../animation";

const AboutSection = () => {
  return (
    <About>
      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titelAnimation}>we work to make</motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titelAnimation}>
              your <span>dreams </span>come
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titelAnimation}>true</motion.h2>
          </Hide>
        </motion.div>
        <motion.p variants={fade}>
          contact us for any ... Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Error, porro? Qui quam blanditiis fugit eligendi
          reiciendis minus ipsum veniam officiis velit possimus quae magni
          beatae, nam officia fuga placeat quisquam laboriosam perferendis
          deleniti ducimus, pariatur, sunt soluta! Est odit repudiandae
          quibusdam magni eius aperiam aliquam fugiat saepe deserunt, assumenda
          consequatur?
        </motion.p>
        <Link to='/contact'>
          <motion.button variants={fade}>Contact us</motion.button>
        </Link>
      </Description>
      <Image>
        <motion.img variants={photoAnim} src={home1img} alt='man with camera' />
      </Image>
      <Wave />
    </About>
  );
};

export default AboutSection;
