import React from "react";
//import icons
import clock from "../img/clock.svg";
import diaphragm from "../img/diaphragm.svg";
import money from "../img/money.svg";
import teamwork from "../img/teamwork.svg";
import home2 from "../img/home2.png";
import { About, Description, Image } from "../styles";
import styled from "styled-components";
import { useScroll } from "./useScroll";
import { scrollReveal } from "../animation";

const ServicesSection = () => {
  const [elements, controls] = useScroll();
  return (
    <Services
      variants={scrollReveal}
      animate={controls}
      inital='hidden'
      ref={elements}
    >
      <Description>
        <h2>
          High <span>quality </span>services
        </h2>
        <Cards>
          <Card>
            <div className='icon'>
              <img src={clock} alt='clock icon' />
              <h3>efficient</h3>
            </div>
            <p>Lorem ipsum dolor sit amet. </p>
          </Card>
          <Card>
            <div className='icon'>
              <img src={teamwork} alt='v icon' />
              <h3>teamwork</h3>
            </div>
            <p>Lorem ipsum dolor sit amet.</p>
          </Card>
          <Card>
            <div className='icon'>
              <img src={diaphragm} alt='diaphragm icon' />
              <h3>on point</h3>
            </div>
            <p>Lorem ipsum dolor sit amet.</p>
          </Card>
          <Card>
            <div className='icon'>
              <img src={money} alt='money icon' />
              <h3>affordable</h3>
            </div>
            <p>Lorem ipsum dolor sit amet.</p>
          </Card>
        </Cards>
      </Description>
      <Image>
        <img src={home2} alt='img with cam' />
      </Image>
    </Services>
  );
};

const Services = styled(About)`
  h2 {
    padding-bottom: 5rem;
  }
  p {
    width: 70%;
    padding: 2rem 0rem 4rem 0rem;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    justify-content: center;
  }
`;

const Card = styled.div`
  flex-basis: 20rem;
  .icon {
    display: flex;
    align-items: center;
    h3 {
      margin-left: 1rem;
      background: transparent;
      color: white;
      padding: 1rem;
    }
  }
`;

export default ServicesSection;
