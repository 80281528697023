import React from "react";
//animation
import { motion } from "framer-motion";
import { pageAnimation, titelAnimation } from "../animation";
import styled from "styled-components";

const ContactUs = () => {
  return (
    <ContactStyle
      exit='exit'
      variants={pageAnimation}
      initial='hidden'
      animate='show'
      style={{ background: "#fff" }}
    >
      <ContactContainer>
        <Title>
          <Hide>
            <motion.h2 variants={titelAnimation}>get in touch</motion.h2>
          </Hide>
        </Title>
        <div>
          <Hide>
            <Social variants={titelAnimation}>
              <Circle />
              <h2>send us a email</h2>
            </Social>
          </Hide>
          <Hide>
            <Social variants={titelAnimation}>
              <Circle />
              <h2>contact us on twitter</h2>
            </Social>
          </Hide>
          <Hide>
            <Social variants={titelAnimation}>
              <Circle />
              <h2>call us</h2>
            </Social>
          </Hide>
          <Hide>
            <Social variants={titelAnimation}>
              <Circle />
              <h2>
                <a href='http://impressum.loefflermartin.de' target='_blank'>
                  german impressum
                </a>
              </h2>
            </Social>
          </Hide>
        </div>
      </ContactContainer>
    </ContactStyle>
  );
};

const ContactStyle = styled(motion.div)`
  padding: 5rem 10rem;
  color: #353535;
  min-height: 90vh;
  @media (max-width: 1300px) {
    padding: 2rem;
    h2 {
      font-size: 2rem;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 4rem;
  color: black;
  @media (max-width: 1300px) {
    margin-top: 5rem;
  }
`;

const Hide = styled.div`
  overflow: hidden;
`;

const Circle = styled.div`
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  background: #353535;
`;
const Social = styled(motion.div)`
  display: flex;
  align-items: center;
  h2 {
    margin: 2rem;
    a {
      text-decoration: none;
      color: #353535;
    }
  }
`;

const ContactContainer = styled.div`
  @media (max-width: 1300px) {
    margin-left: 5rem;
  }
`;

export default ContactUs;
